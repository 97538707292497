import axios from "axios";
import { v2Request } from "../helpers/request";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL } = process.env;
let endpoints = {}; //dev
endpoints.getAllCompanies = REACT_APP_API_URL + "/company";
endpoints.create = REACT_APP_API_URL + "/company/create";
endpoints.createDiet = REACT_APP_API_URL + "/company/dieterary";
endpoints.preapprovals = REACT_APP_API_URL + "/company/preapprovals";
endpoints.admin = REACT_APP_API_URL + "/admin";

let create = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoints.create,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

// This seems to be for reps
let addLogo = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoints.getAllCompanies + "/logo",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

// This seems to be for clients
let addLogoAdmin = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoints.admin + "/logo",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let addPreferences = (payload) => {
  return v2Request("/offices/preferences", {
    body: JSON.stringify(payload),
    method: "POST",
  });
};

let createDiet = (payload) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoints.createDiet,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateDiet = (payload) => {
  return v2Request("/offices/dietary", {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

const upsertPreApproval = (payload, repId) => {
  return v2Request(`/offices/preapprovals/${repId}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

let edit = (payload) => {
  return v2Request("/offices/profile", {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

let getAllCompanies = (pageIndex, pageSize) => {
  const config = {
    method: "GET",
    url:
      endpoints.getAllCompanies +
      "?" +
      "offset=" +
      pageIndex +
      "&rowcount=" +
      pageSize,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getDiet = () => {
  return v2Request("/offices/dietary");
};

let getLogo = () => {
  const config = {
    method: "GET",
    url: endpoints.getAllCompanies + "/logo",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getLogoAdmin = (userId) => {
  console.log(userId, "axios");
  const config = {
    method: "GET",
    url: endpoints.admin + "/logo/" + userId,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getPreference = () => {
  return v2Request("/offices/preferences");
};

let logoUpdate = (payload, id) => {
  const config = {
    data: payload,
    method: "PUT",
    url: endpoints.getAllCompanies + "/logo/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let logoUpdateAdmin = (payload, id) => {
  const config = {
    data: payload,
    method: "PUT",
    url: endpoints.admin + "/logo/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

const requirementsAddOrUpdate = (payload) => {
  return v2Request("/offices/requirements", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

// let preferencesUpdate = (payload, id) => {
//   const config = {
//     data: payload,
//     method: "PUT",
//     url: endpoints.getAllCompanies + "/preference/" + id,
//     withCredentials: true,
//     crossdomain: true,
//     headers: { "Content-Type": "application/json" },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

// let preferencesDelete = (id) => {
//   const config = {
//     method: "DELETE",
//     url: endpoints.getAllCompanies + "/preference/" + id,
//     withCredentials: true,
//     crossdomain: true,
//     headers: { "Content-Type": "application/json" },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

let preferencesDelete = (id) => {
  return v2Request(`/offices/preferences/${id}`, { method: "DELETE" });
};

let preferencesUpdate = (payload, id) => {
  return v2Request(`/offices/preferences/${id}`, {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

const updateOfficeResturantPreferences = (payload) => {
  return v2Request(`/offices/resturants/preferences`, {
    body: JSON.stringify(payload),
    method: "PUT",
  });
};

let selectRegistrationCompanyById = (id) => {
  const config = {
    method: "GET",
    url: endpoints.getAllCompanies + "/registration/" + id,
    withCredentials: true,
    crossdomain: true,
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getRequirementStatusesForClient = () => {
  return v2Request("/offices/requirements/statuses");
};

let blockRepByClient = (payload) => {
  return v2Request("/offices/blocks/rep", {
    method: "POST",
    body: JSON.stringify(payload),
  });
};

let deleteBlockRep = (id) => {
  return v2Request(`/offices/blocks/rep/${id}`, { method: "DELETE" });
};

let getAllPracticeTypes = () => {
  return v2Request("/offices/practice-types");
};

let getCompanyPracticeTypes = (id) => {
  return v2Request(`/offices/practice-types/${id}`);
};

let updatePracticeTypes = (payload) => {
  return v2Request("/offices/practice-types", {
    method: "PUT",
    body: JSON.stringify(payload),
  });
};

const getDelegatedUsersList = () => {
  return v2Request("/offices/delegated");
};

const getDelegatedUserActivity = (userId) => {
  return v2Request(`/offices/activity/${userId}`);
};

const getImpersonationOptions = () => {
  return v2Request("/offices/impersonation-options");
};

const impersonateOffice = (officeId) => {
  return v2Request(`/offices/impersonate/${officeId}`);
};

const getAllOffices = () => {
  return v2Request("/offices");
};

const getOfficeAppointmentRequirements = () => {
  return v2Request("/offices/requirements");
};

const getNotificationsSettings = () => {
  return v2Request("/offices/notification-settings", {
    method: "POST",
  });
};
const updateNotificationsSettings = (data) => {
  return v2Request("/offices/notification-settings", {
    method: "PUT",
    body: JSON.stringify(data),
  });
};
export {
  create,
  selectRegistrationCompanyById,
  addLogoAdmin,
  logoUpdateAdmin,
  getLogoAdmin,
  preferencesUpdate,
  getPreference,
  addLogo,
  addPreferences,
  logoUpdate,
  getLogo,
  edit,
  createDiet,
  updateDiet,
  getAllCompanies,
  getDiet,
  requirementsAddOrUpdate,
  upsertPreApproval,
  getRequirementStatusesForClient,
  preferencesDelete,
  blockRepByClient,
  deleteBlockRep,
  getAllPracticeTypes,
  getCompanyPracticeTypes,
  updatePracticeTypes,
  getAllOffices,
  getImpersonationOptions,
  impersonateOffice,
  getDelegatedUsersList,
  getDelegatedUserActivity,
  getOfficeAppointmentRequirements,
  updateOfficeResturantPreferences,
  getNotificationsSettings,
  updateNotificationsSettings,
};
