import React from "react";
import Logo from "../../../common/Logo";
import useLogout from "../../../common/useLogout";
import { Link } from "react-router-dom";

const NotConfirmedMessage = () => {
  const logOut = useLogout();
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="OnboardingForm"
      >
        <div style={{ width: "100%", maxWidth: 500, margin: "84px 0" }}>
          <div>
            <Logo style={{ width: 200 }} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <h1 style={{ border: "none", marginBottom: 0 }}>Welcome</h1>

            <div
              onClick={logOut}
              style={{
                minWidth: 100,
                cursor: "pointer",
              }}
            >
              <span style={{ marginLeft: 8, fontSize: "1.5rem", opacity: 0.5 }}>✕</span>
            </div>
          </div>
          <p style={{ fontSize: "1.25rem" }}>
            You've registered successfully! You’ll get an email (usually within a business
            day) after we've had a chance to review your profile. If we have any questions
            about any information you provided we will reach out to you.
          </p>
          <Link to="/">
            <button
              className="Button"
              style={{ fontSize: "1.25rem", padding: "1rem", marginTop: 24 }}
            >
              Go Back Home
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotConfirmedMessage;
