import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavNotifications } from "./NavNotifications";
import { UserContext } from "components/context/UserContext";
import { BsChatSquareText } from "react-icons/bs";
import "../../sass/zeplin/ClinicHeader.scss";

export const NavLink = ({ pathname, Img, name, route, onClick = null, ...rest }) => {
  const isSelected = pathname === route;

  const contents = (
    <>
      <Img stroke={isSelected ? "#00adec" : "#224984"} />
      <span>{name}</span>
    </>
  );

  if (typeof onClick === "function") {
    return (
      <a onClick={onClick} href="#" {...rest}>
        {contents}
      </a>
    );
  }

  return (
    <Link to={pathname} className={isSelected ? "--selected" : ""} {...rest}>
      {contents}
    </Link>
  );
};

const NavHeader = ({ links, renderLinks }) => {
  const { pathname } = useLocation();
  const { state } = useContext(UserContext);
  const { unreadChats, newAnnouncements, activeFeatures } = state;
  const [smallScreen, setSmallScreen] = useState(false);

  const onScreenSizeChange = () => {
    if (!window) return;
    if (window.innerWidth <= 575) {
      setSmallScreen(true);
    } else setSmallScreen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onScreenSizeChange);
    return () => {
      window.removeEventListener("resize", onScreenSizeChange);
    };
  }, []);

  return (
    <div className="NavHeader" data-cy="nav-head">
      <img
        className="Logo"
        alt="MyRep"
        src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
      />
      <nav className="ClinicNav">
        {links.map((l) => {
          if (l.name === "Announcements") {
            return <NavNotifications key={l.name} route={pathname} {...l} />;
          } else {
            return <NavLink key={l.name} route={pathname} data-cy={`nav-link-${l.name.toLowerCase()}`} {...l} />;
          }
        })}

        {activeFeatures.includes("IN_APP_MESSAGING") && (
          <Link to={"/messages"}>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <BsChatSquareText
                  size={30}
                  fill={pathname === "/messages" ? "#00adec" : "#224984"}
                  style={{ marginLeft: 10 }}
                />

                <div
                  style={{
                    color: pathname === "/messages" ? "#00adec" : "#224984",
                    marginTop: smallScreen ? 12 : 22,
                    fontSize: 12,
                    textAlign: "center",
                  }}
                >
                  Messages
                </div>
              </div>
              {(unreadChats.length > 0 || newAnnouncements.length > 0) && (
                <span
                  style={{
                    color: "#fff",
                    background: "green",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    textAlign: "center",
                    fontSize: 12,
                    position: "absolute",
                    top: -10,
                    right: 0,
                  }}
                >
                  {unreadChats.length + newAnnouncements.length}
                </span>
              )}
            </div>
          </Link>
        )}

        {typeof renderLinks === "function" && renderLinks()}
      </nav>
    </div>
  );
};

export default NavHeader;
