import React, { useState, useEffect } from "react";
import "../pricing.css";
import Card from "../components/common/Card";
import { Link, useHistory } from "react-router-dom";
import "../sass/zeplin/Pricing.scss";
import Logo from "./common/Logo";

const SelectableMiniCardGroup = ({ children, style }) => {
  return (
    <div className="SelectableMiniCardGroup" style={style}>
      {children}
    </div>
  );
};

const SelectableMiniCard = ({ children, isSelected, ...rest }) => {
  return (
    <div className={`SelectableMiniCard ${isSelected ? " --selected" : ""}`} {...rest}>
      {children}
    </div>
  );
};

export default function PricingTable() {
  const [selected, setSelected] = useState("monthly"); // monthly, yearly, free
  const history = useHistory();

  const onClickStart = () => {
    if (["monthly", "yearly"].includes(selected)) {
      // rep/premier
      history.push(`/rep/premier/${selected}`);
    } else {
      history.push("/rep/basic");
    }
    // rep/basic
  };
  return (
    <React.Fragment>
      <Logo className="TopAndLeftLogo" />
      <div className="Pricing">
        <div className="LeadIn">
          <strong>Select your plan:</strong>
        </div>
        <div className="PriceCardContainer" style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))", gap: "1rem"}}>
          <Card isSelected={["monthly", "yearly"].includes(selected)} isSelectable>
            <h2>Premier</h2>
            <ul class="FeaturesList">
              <li>Unlimited appointment scheduling from anywhere.</li>
              <p>(Subject to the parameters set by each office)</p>
              <li>Real-Time Scheduling for Appointments</li>
              <li>Receive Real-Time Alerts</li>
              <li>Transfer Appointments</li>
            </ul>
            <SelectableMiniCardGroup style={{ marginTop: 44 }}>
              <SelectableMiniCard
                onClick={() => setSelected("monthly")}
                isSelected={selected === "monthly"}
              >
                Monthly @ $99.99
              </SelectableMiniCard>
              <SelectableMiniCard
                isSelected={selected === "yearly"}
                onClick={() => setSelected("yearly")}
              >
                Yearly @ $999
              </SelectableMiniCard>
            </SelectableMiniCardGroup>
          </Card>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            isSelected={selected === "free"}
            onClick={() => setSelected("free")}
            isSelectable
          >
            <h2>Basic</h2>

            <div>
              <ul className="FeaturesList">
                <li>Schedule appointments from anywhere.</li>
                <p>Unlimited access to appointments</p>
              </ul>
              <SelectableMiniCard
                style={{ marginTop: 44 }}
                isSelected={selected === "free"}
              >
                FREE
              </SelectableMiniCard>
            </div>
          </Card>
        </div>
        <button
          className="Button"
          style={{
            width: "100%",
            maxWidth: 500,
            margin: "32px auto",
            fontWeight: 600,
            padding: "12px 0",
          }}
          onClick={onClickStart}
        >
          Get started
        </button>
      </div>
    </React.Fragment>
  );
}
