import React from 'react';
import TopLogo from './TopLogo';
import Logo from './Logo.js';
import MainFooter from '../../components/layout/MainFooter';

const CheckYourEmail = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
      <div style={{ maxWidth: 500, width: '100%', margin: '42px auto' }}>
        <Logo style={{ maxWidth: 100, marginBottom: 24 }} />
        <div>
          <h2>Please check your email</h2>
          <p>We've sent an email to the email address you just registered with.</p>
        </div>
      </div>
      <MainFooter />
    </div>
  )
};

export default CheckYourEmail;
