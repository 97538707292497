import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { UserContext } from "./context/UserContext";

const KEY = "MAINTENANCE_PERIOD_END_DATE";

const ServiceModeBanner = () => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState("");
  const { state } = useContext(UserContext)!;

  const getServiceModeStatus = async () => {
    const value = sessionStorage.getItem("serviceMode");
    if (!value) {
      const res = state.config;

      if (res && Array.isArray(res)) {
        const data = res.find((e) => e.key === KEY);
        const currentDate = moment();
        const targetDate = moment(data?.value);
        const dateOk = targetDate.isAfter(currentDate);
        if (dateOk && data?.is_active) {
          sessionStorage.setItem(
            "serviceMode",
            JSON.stringify({
              status: "ACTIVE",
              date: targetDate.format("MMM DD, YYYY h:mm A"),
            })
          );
          setShow(true);
          setDate(targetDate.format("MMM DD, YYYY h:mm A"));
        } else {
          sessionStorage.setItem("serviceMode", JSON.stringify({ status: "DISABLED", date: "" }));
        }
      } else {
        sessionStorage.setItem("serviceMode", JSON.stringify({ status: "DISABLED", date: "" }));
      }
    } else {
      const data = JSON.parse(value);
      if (data.status === "ACTIVE") {
        setShow(true);
        setDate(data.date);
      }
    }
  };

  useEffect(() => {
    if (state.currentUser.id) {
      getServiceModeStatus();
    }
  }, [state.currentUser.id]);

  return show ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        width: "99vw",
        color: "#fff",
        background: "#224984",
        padding: "2px 10px",
        fontSize: 14,
        textAlign: "center",
      }}
    >
      This site is undergoing maintenance and may not be functioning properly until after {date}.
    </div>
  ) : null;
};

export default ServiceModeBanner;
