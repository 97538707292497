import React, { useEffect, useState } from "react";
import "./auth.css";
import LoginInput from "./common/LoginInput";
import { iResetForm } from "./common/types";
import { initialResetForm } from "./common/initialValues";
import { useHistory, useParams } from "react-router-dom";
import * as passwordService from "services/emailService";
import swal from "sweetalert";

interface ResetPasswordProps {}
const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const [formData, setFormData] = useState<iResetForm>(initialResetForm);

  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const handleChange = (prop: string) => (value: string) => {
    setFormData((prev: iResetForm) => ({
      ...prev,
      [prop]: value,
    }));
  };

  const handleSubmit = () => {
    passwordService
      .resetPass(formData)
      .then((res) => {
        if (res.error) throw new Error(res.error);
        swal({
          icon: "success",
          title: "Success",
        });
        history.push("/login");
      })
      .catch((error) => {
        swal({
          icon: "warning",
          title: error.message,
        });
      });
  };

  useEffect(() => {
    if (token) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        token,
      }));
    }
  }, [token]);
  return (
    <div className="login-container">
      <div className="login-content-container">
        <p className="login-form-title">Reset your password</p>
        <div className="login-form-container">
          <LoginInput
            label="New Password"
            value={formData?.password}
            name={"password"}
            onChange={handleChange}
            type="password"
            placeholder="Enter new password"
          />
          <LoginInput
            label="Confirm New Password"
            value={formData?.passwordConfirm}
            name={"passwordConfirm"}
            onChange={handleChange}
            type="password"
            placeholder="Confirm new password"
          />
          <button className="login-form-btn" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
