import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../assets/images/logo/logo.png";
import { Button } from "react-bootstrap";
import { CgLogOff } from "react-icons/cg";
import { useHistory, useLocation } from "react-router-dom";
import useLogout from "components/common/useLogout";

const LINKS = [
  { pathname: "/admin/approvals", name: "Approvals" },
  { pathname: "/admin/invite", name: "Invite" },
];

const AdminNavbar = () => {
  const handleLogout = useLogout();
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img style={{ width: 150 }} src={Logo} alt="My Rep" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse className="justify-content-end">
          {LINKS.map((el) => (
            <Button
              onClick={() => history.push(el.pathname)}
              size="sm"
              className="mx-1"
              style={{
                color: "#224984",
                background: "transparent",
                border: "1px solid #224984",
                ...(pathname === el.pathname && {
                  background: "#224984",
                  border: "none",
                  color: "#fff",
                }),
              }}
            >
              {el.name}
            </Button>
          ))}

          <Button
            onClick={handleLogout}
            variant="outline-secondary"
            size="sm"
            className="mx-1 ml-4"
          >
            <CgLogOff style={{ marginTop: -2 }} size={20} /> Logout
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AdminNavbar;
