import { isValidEmail } from "components/common/utilities";

export const validationMessages = {
  required: "This field is required.",
  email: "This must be a real e-mail address.",
  url: "This must start with http:// or https://",
};

export function validate(value, rules, showErrorMessage = false) {
  let isValid = true;
  const errorMessages = [];
  if (rules?.required) {
    if (typeof value === "string") {
      isValid = !!value && value.trim() !== "" && isValid;
    } else if (typeof value === "number") {
      isValid = (value || value === 0) && isValid;
    } else {
      isValid = false;
    }
    if (showErrorMessage && !isValid) {
      errorMessages.push(validationMessages.required);
    }
  }

  if (rules?.minLength && typeof value === "string") {
    isValid = value.trim().length >= rules?.minLength && isValid;
  }
  if (rules?.maxLength && typeof value === "string") {
    isValid = value.trim().length <= rules?.maxlength && isValid;
  }
  if (rules?.min || rules?.min === 0) {
    isValid = value >= rules?.min && isValid;
  }
  if (rules?.max || rules?.min === 0) {
    isValid = value <= rules?.max && isValid;
  }
  if (rules?.list) {
    isValid = isValid && rules?.list.includes(value);
  }
  if (rules?.objectId) {
    isValid = isValid && RegExp("[0-9a-fA-F]{24}").test(value);
  }
  if (rules?.number) {
    isValid = isValid && !isNaN(value) && typeof parseInt(value, 10) === "number";
  }

  if (rules?.date) {
    var dateVal = new Date(value);
    isValid = isValid && dateVal instanceof Date && !isNaN(dateVal.valueOf());
  }
  if (rules?.zipCode) {
    isValid = isValid && /^\d{5}(?:[-\s]\d{4})?$/.exec(value);
  }

  if (rules?.email) {
    isValid = isValid && isValidEmail(value);
    if (!isValid) errorMessages.push(validationMessages.email);
  }

  if (rules?.password) {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
    isValid = isValid && passwordRegex.test(value);
  }

  if (rules?.url) {
    const isUrlValid = value === "" || /^https?:\/\//i.test(value);
    isValid = isValid && isUrlValid;
    if (!isUrlValid && showErrorMessage) {
      errorMessages.push(validationMessages.url);
    }
  }

  return showErrorMessage ? errorMessages : isValid;
}

export function onChange(event) {
  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;
  this.setState((prevState) => {
    const field = { ...prevState.formData[name] };
    field.value = value;
    field.touched = true;
    field.valid = validate(field.value, field.validation);
    const formData = { ...prevState.formData, [name]: field };
    let formValid = true;
    for (let inputIdentifier in formData) {
      formValid = formValid && formData[inputIdentifier.valid];
    }
    return { formData: formData, formValid: formValid };
  });
}
