import React from "react";
import "./css/redesign-components.css";
import "./css/contact-section.css";
import logo from "assets/images/home/contactLogo.png";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import {
  // IoIosSend,
  IoMdPin,
} from "react-icons/io";

interface ContactSectionProps {}

// const MAIN_PAGES = [
//   {
//     label: "Home",
//     link: "#",
//   },
//   {
//     label: "FAQ",
//     link: "#",
//   },
//   {
//     label: "Contact us",
//     link: "#",
//   },
//   {
//     label: "Page Link",
//     link: "#",
//   },
// ];
// const HELPING_LINKS = [
//   {
//     label: "Blogs",
//     link: "#",
//   },
//   {
//     label: "Events",
//     link: "#",
//   },
//   {
//     label: "Support",
//     link: "#",
//   },
// ];
const ContactSection: React.FC<ContactSectionProps> = () => {
  // const [userEmail, setUserEmail] = useState<string>("");

  // const handleSubmit = () => {
  //   //do something with the email
  //   console.log({ userEmail });
  // };
  return (
    <div className="section-container" style={{ gap: "2px" }}>
      <div className="c-image-bg">
        <div className="c-left">
          <img src={logo} alt="" className="c-logo" />
          <p className="c-description">
            MyRep provides medical practices with an easy-to-use platform for automated rep scheduling.
          </p>
          <div className="c-contact">
            <a href="mailto:support@myrepapp.com" className="c-contact-item">
              <div className="c-icon-container">
                <MdEmail />
              </div>
              support@myrepapp.com
            </a>
            <a href="tel:8336973737" className="c-contact-item">
              <div className="c-icon-container">
                <FaPhoneAlt />
              </div>
              (833-697-3737)
            </a>
            <a
              href="https://maps.google.com/?q=833-MYREP37"
              target="_blank"
              className="c-contact-item"
              rel="noreferrer"
            >
              <div className="c-icon-container">
                <IoMdPin />
              </div>
              833-MYREP37
            </a>
          </div>
        </div>
        {/* <div className="c-right">
          <div className="c-links-container">
            <div className="c-main-pages">
              <h3>Main pages</h3>
              <ul>
                {MAIN_PAGES.map((page, index) => (
                  <li key={index}>
                    <a href={page?.link}>{page.label}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="c-helping-links">
              <h3>Helping Links</h3>
              <ul>
                {HELPING_LINKS.map((page, index) => (
                  <li key={index}>
                    <a href={page?.link}>{page.label}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="c-divider" />
          <div className="c-subscribe">
            <h3>Subscribe</h3>
            <p>Stay updated with the latest news</p>
            <form>
              <div className="c-input-container">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="c-subscribe-input"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
                <button type="submit" className="c-subscribe-button" onClick={handleSubmit}>
                  <IoIosSend />
                </button>
              </div>
            </form>
          </div>
        </div> */}
      </div>
      <div className="c-footer-container">
        <p className="c-legal-text">© 2024 MyRep. All rights reserved.</p>
        <div>
          <a className="c-legal-text" href="https://myrepapp-assets.s3-us-west-1.amazonaws.com/privacy.pdf">
            Privacy Policy
          </a>
          {" | "}
          <a className="c-legal-text" href="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
