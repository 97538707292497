import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import * as adminServices from "../../services/adminServices";
import { Link, useHistory } from "react-router-dom";
import { makeToast } from "helpers/common";

export const NavNotifications = ({ pathname, Img, name, route }) => {
  const history = useHistory();
  const [announcements, setAnnouncements] = useState([]);
  useEffect(() => {
    getAllAnnouncements();
  }, []);

  const getAllAnnouncements = () => {
    adminServices.getAllAnnouncements(0, 20, false).then(onGetAnnouncementsSuccess);
  };

  const onGetAnnouncementsSuccess = (e) => {
    console.log(e);
    let items = e.item.pagedItems.length;
    let Announcements = e.item.pagedItems;
    setAnnouncements(Announcements);
  };

  const isSelected = pathname === route;
  const contents = (
    <>
      <Img
        stroke={isSelected ? "#00adec" : "#224984"}
        style={{ marginBottom: 5, marginLeft: 25 }}
      />
      <span>{name}</span>
    </>
  );

  const onClicktoast = (e) => {
    let data = {
      announcementId: e.id,
      IsRead: true,
    };
    adminServices
      .setReadAnnouncement(data)
      .then(getAllAnnouncements)
      .catch(onErrorResponse);
  };

  const onErrorResponse = (e) => {
    makeToast(`Please Try Again. ${e.response.data.errors[0]}`,'error');
  };

  return (
    <>
      <Link to={"/notifications/list"} style={{ position: "relative" }}>
        {announcements && announcements.length > 0 && (
          <span
            className="badge rounded-pill bg badge-danger"
            style={{ position: "absolute", right: 10 }}
          >
            {announcements.length}
          </span>
        )}
        <div
          style={{ padding: ".5rem 1rem" }}
          onClick={() => history.push("/common/Notifications")}
        >
          {contents}
        </div>
      </Link>
    </>
  );
};
