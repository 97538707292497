import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import TextInput from "../common/TextInput";
import { UserContext } from "../../components/context/UserContext";
import * as profileServices from "../../services/profileServices";
import * as userServices from "../../services/userServices";
import * as companyServices from "../../services/companyServices";
import * as productServices from "../../services/productService";
import * as locationServices from "../../services/locationServices";
import * as emailServices from "../../services/emailService";
import ImageSpecificiationsWarning from "../common/ImageSpecificationsWarning";
import Select from "../common/Select";
import { LogoutIcon } from "./ClinicHeader";

import FileUpload from "../files/FileUpload";
import "./OnboardingForm.scss";
import Logo from "../common/Logo";
import MainFooter from "../layout/MainFooter";
import { useHistory } from "react-router-dom";
import { validate as isValid } from "../../helpers/validation.helper";
import useLogout from "./useLogout";
import { makeToast } from "helpers/common";

const ValidatedTextInput = ({ rules, showAllValidationErrors, ...rest }) => {
  const [showErrors, setShowErrors] = useState(false);
  const [value, setValue] = useState();

  const validErrors = isValid(value, rules, true); // true = show validation errors

  return (
    <div>
      {validErrors.length > 0 && (showAllValidationErrors || showErrors) && (
        <ErrorMessages errors={validErrors} />
      )}
      <TextInput
        onBlur={(e) => {
          console.log(isValid(e.target.value, rules));
          setShowErrors(!isValid(e.target.value, rules));
        }}
        {...rest}
        onChange={(e) => {
          if (showErrors) {
            // re-evaluate to see if still invalid
            if (isValid(e.target.value, rules)) {
              setShowErrors(false);
            }
          }

          setValue(e.target.value);
          // carry on by calling original function
          if (typeof rest.onChange === "function") rest.onChange(e);
        }}
      />
    </div>
  );
};

const ErrorMessages = ({ errors }) => {
  return errors.map((e) => (
    <p style={{ color: "salmon", marginBottom: 6 }}>{e}</p>
  ));
};

export const defaultValidatedFields = {
  firstName: { rules: { required: true } },
  lastName: { rules: { required: true } },
  email: { rules: { required: true, email: true } },
  phone: { rules: { required: true } },
  url: { rules: { url: true, required: true } },
  lineOne: { rules: { required: true } },
  city: { rules: { required: true } },
  zip: { rules: { required: true } },
};

const set = (setter, data) => (key) => (e) => {
  return setter({ ...data, [key]: e.target.value });
};

const OnboardingForm = ({
  isRep = true,
  locationExplanation = "Where is your office or workplace located?",
  contactExplanation = "How can medical offices get in touch with you?",
  photoUploadExplanation = "Upload your profile photo",
  onSubmit,
  preRedirect = null,
  labels = {
    website: "Website url",
    firstName: "First name",
    lastName: "Last name",
    name: "Company name",
    email: "Contact e-mail",
    phone: "Contact phone",
  },
  validatedFields = defaultValidatedFields,
}) => {
  const history = useHistory();
  const logOut = useLogout();

  const saveUserData = (userData) => {
    if (!userData.id) {
      return profileServices.create(userData).then(console.log);
    }
    return profileServices.edit(userData, userData.id).then(console.log); // TODO: warning! is this right?
  };

  const saveCompanyData = (companyData) => {
    return companyServices.create(companyData);
  };

  const saveLocationData = (locationData) => {
    return locationServices.create(locationData);
  };

  const saveProductData = (productData) => {
    return productServices.create(productData);
  };

  const saveFoodData = (foodData) => {
    return companyServices.createDiet(foodData);
  };

  const sendUserProfileConformationEmail = () => {
    return emailServices.profileConfirmationEmail();
  };

  const [showAllValidationErrors, setShowAllValidationErrors] = useState(false);

  const { state, dispatch } = useContext(UserContext);
  const { isLoading } = state;
  
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    fileUrl: "",
  });

  const [companyData, setCompanyData] = useState({
    email: "",
    name: "",
    phone: "",
    url: "",
    timezone: "US/Pacific",
    policies: "",
  });

  const [productData, setProductData] = useState({
    productOne: "",
    productTwo: "",
    productThree: "",
    productFour: "",
    productFive: "",
  });

  const [foodData, setFoodData] = useState({
    amount: "",
    vegetarian: "",
    vegan: "",
    lactose: "",
    nuts: "",
    fileUrl: "",
    instructions: "",
  });

  const [locationData, setLocationData] = useState({});
  const [preferences, setPreferences] = useState("");

  const setUD = set(setUserData, userData); // user data (user_profiles)
  const setCD = set(setCompanyData, companyData); // company data
  const setLD = set(setLocationData, locationData); // company data
  const setPD = set(setProductData, productData);
  const setFP = set(setFoodData, foodData);

  const onSave = async () => {
    const allData = { ...userData, ...companyData, ...locationData };
    const isFormValid = Object.keys(validatedFields).every((fieldKey) => {
      return isValid(allData[fieldKey], validatedFields[fieldKey].rules);
    });

    if (!isFormValid) {
      setShowAllValidationErrors(true);
      return;
    }

    try {
      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: true },
      });
      const profileData = {
        user: userData,
        company: companyData,
        location: locationData,
      };
      if (isRep) profileData.products = productData;
      else profileData.food = foodData; // TODO - check this assumption if another user type is added

      await profileServices.createProfile(profileData);
      if (!isRep && preferences) {
        await companyServices.addPreferences({ preferences });
      }
      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: "SET_LOADING_STATE",
        payload: { isLoading: false },
      });
      // swal({ icon: "error", title: "Something went wrong", text: err.message });
      makeToast(`Something went wrong. ${err.message}`, "error");
    }
    makeToast("Thanks for that information. You can make changes to your profile at any time you want to by visiting your Profile page", "success");


    const redirectTo = isRep ? "/rep/accounts" : "/client/calendar";

    if (typeof onSubmit === "function") {
      onSubmit();
    }

    if (typeof preRedirect === "function") {
      await preRedirect()
      history.push(redirectTo)
    } else {
      history.push(redirectTo);
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="OnboardingForm"
      >
        <div style={{ width: "100%", maxWidth: 500, margin: "84px 0" }}>
          <div>
            <Logo style={{ width: 200 }} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <h1 style={{ border: "none", marginBottom: 0 }}>Welcome</h1>

            <div
              onClick={logOut}
              style={{
                minWidth: 100,
                cursor: "pointer",
              }}
            >
              <span style={{ marginLeft: 8, fontSize: "1.5rem", opacity: 0.5 }}>
                ✕
              </span>
            </div>
          </div>
          <p style={{ fontSize: "1.25rem" }}>
            We need a bit more information to get you started.
          </p>
          <div>
            <ValidatedTextInput
              label={labels.firstName}
              rules={{ required: true }}
              onChange={setUD("firstName")}
              showAllValidationErrors={showAllValidationErrors}
            />
          </div>
          <div>
            <ValidatedTextInput
              rules={{ required: true }}
              label={labels.lastName}
              onChange={setUD("lastName")}
              showAllValidationErrors={showAllValidationErrors}
            />
          </div>

          <div
            style={{
              margin: "24px auto 12px auto",
            }}
          >
            <div
              style={{
                width: 100,
                height: 100,
                borderRadius: 5,
                overflow: "hidden",
              }}
            >
              <img
                src={userData.fileUrl || "/placeholder-avatar.svg"}
                style={{ width: 100, height: "auto" }}
              />
            </div>
            <FileUpload
              onSuccess={(res) => {
                console.log(res);
                setUserData({ ...userData, fileUrl: res[0] });
              }}
              labelClassName="Button ButtonGray"
            >
              {photoUploadExplanation}
            </FileUpload>
            <p>
              <ImageSpecificiationsWarning />
            </p>
          </div>

          <h2>Contact information</h2>
          <p>{contactExplanation}</p>

          <ValidatedTextInput
            value={companyData.name}
            label={labels.name}
            onChange={setCD("name")}
            rules={{ required: true }}
            showAllValidationErrors={showAllValidationErrors}
          />
          {!isRep && (
            <>
              <Select
                options={moment.tz
                  .names()
                  .filter((name) => name.includes("US/"))}
                label="Timezone"
                value={companyData.timezone}
                onChange={setCD("timezone")}
              />
            </>
          )}
          <ValidatedTextInput
            value={companyData.email}
            label={labels.email}
            rules={{ required: true, email: true }}
            onChange={setCD("email")}
            showAllValidationErrors={showAllValidationErrors}
          />
          <ValidatedTextInput
            value={companyData.phone}
            label={labels.phone}
            onChange={setCD("phone")}
            rules={{ required: true }}
            type="phone"
          />
          <ValidatedTextInput
            value={companyData.url}
            label={labels.website}
            placeholder={"https://"}
            rules={validatedFields.url.rules}
            onChange={setCD("url")}
            showAllValidationErrors={showAllValidationErrors}
          />
          <h2>Your location</h2>
          <p>{locationExplanation}</p>
          <ValidatedTextInput
            rules={{ required: true }}
            name="lineOne"
            type="text"
            value={locationData.lineOne}
            label="Address"
            autoComplete="on"
            onChange={setLD("lineOne")}
            showAllValidationErrors={showAllValidationErrors}
          />
          <TextInput
            name="lineTwo"
            type="text"
            value={locationData.lineTwo}
            label="Apt/Unit"
            autoComplete="on"
            onChange={setLD("lineTwo")}
          />

          <ValidatedTextInput
            name="city"
            type="text"
            rules={{ required: true }}
            value={locationData.city}
            label="City"
            autoComplete="on"
            onChange={setLD("city")}
            showAllValidationErrors={showAllValidationErrors}
          />
          <ValidatedTextInput
            rules={{ required: true }}
            name="zip"
            type="zip"
            value={locationData.zip}
            label="Zip"
            autoComplete="on"
            onChange={setLD("zip")}
            showAllValidationErrors={showAllValidationErrors}
          />

          {!isRep && (
            <>
              <h2>Food Preferences</h2>
              <p>
                Select how many meals you'll require as well as which food
                limitations your office members have.
              </p>

              <TextInput
                label="Total # of Orders"
                value={foodData.amount}
                onChange={setFP("amount")}
              />
              <TextInput
                label="# of Vegetarian Orders"
                value={foodData.vegetarian}
                onChange={setFP("vegetarian")}
              />
              <TextInput
                label="# of Vegan Orders"
                value={foodData.vegan}
                onChange={setFP("vegan")}
              />
              <TextInput
                label="# of Lactose Allergy Orders"
                value={foodData.lactose}
                onChange={setFP("lactose")}
              />
              <TextInput
                label="# of Nut Allergy Orders"
                value={foodData.nuts}
                onChange={setFP("nuts")}
              />

              <TextInput
                label="Food Instructions"
                value={foodData.instructions}
                onChange={setFP("instructions")}
              />

              <TextInput
                label="Preferred Resturant (You can add more later)"
                value={preferences}
                onChange={(e) => setPreferences(e.target.value)}
              />

              <h2>Other Policies</h2>
              <TextInput
                html="textarea"
                label="What else would be useful for reps to know?"
                value={companyData.policies}
                onChange={setCD("policies")}
              />
            </>
          )}
          {isRep && (
            <>
              <h2>Products</h2>
              <p>List the products that you deal with</p>

              <TextInput
                name="productOne"
                type="text"
                value={productData.productOne}
                label="Product No.1"
                autoComplete="on"
                onChange={setPD("productOne")}
              />
              <TextInput
                name="productTwo"
                type="text"
                value={productData.productTwo}
                label="Product No.2"
                autoComplete="on"
                onChange={setPD("productTwo")}
              />
              <TextInput
                name="productThree"
                type="text"
                value={productData.productThree}
                label="Product No.3"
                autoComplete="on"
                onChange={setPD("productThree")}
              />
              <TextInput
                name="productFour"
                type="text"
                value={productData.productFour}
                label="Product No.4"
                autoComplete="on"
                onChange={setPD("productFour")}
              />
              <TextInput
                name="productFive"
                type="text"
                value={productData.productFive}
                label="Product No.5"
                autoComplete="on"
                onChange={setPD("productFive")}
              />
            </>
          )}
          <button
            className="Button"
            disabled={isLoading}
            style={{ fontSize: "1.25rem", padding: "1rem", marginTop: 24 }}
            onClick={onSave}
          >
            {!isLoading ? " Save" : <i className="fa fa-spinner fa-spin"></i>}
          </button>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default OnboardingForm;
