import React from "react";

// An input with an inline label
const TextInput = ({
  type = "text",
  html = "input",
  label = "",
  name = "",
  containerStyles = {},
  labelStyles = {},
  onChange,
  ...rest
}) => {
  const formatPhoneNumber = (event) => {
    const phoneNumber = event.target.value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "$1-$2-$3"
    );
    event.target.value = formattedPhoneNumber;
    onChange(event);
  };

  const Type = html;
  let input = (
    <Type
      type={type}
      className="Input"
      name={name}
      onChange={onChange}
      {...rest}
    />
  );
  if (type === "phone") {
    input = (
      <Type
        type={type}
        className="Input"
        name={name}
        maxLength={12}
        onChange={formatPhoneNumber}
        {...rest}
      />
    );
  }

  if (!label) return input;

  return (
    <div
      className="TextInputContainer"
      style={{ position: "relative", width: "100%", ...containerStyles }}
    >
      <label
        style={{
          ...labelStyles,
          color: "#1f4784",
          position: "absolute",
          left: 16,
          top: 0,
          fontSize: 12,
          width: "100%",
        }}
        htmlFor={name}
      >
        {label}
      </label>
      {input}
    </div>
  );
};

export default TextInput;
