export const isClinicProfileComplete = (account) => {
  if (!account) return false;
  const { firstName, phone, email, name, city, zip } = account;
  if (firstName && name && (phone || email) && (city || zip)) {
    // Must be able to know the name of the company, get in touch with them, and specify city or zip
    return true;
  }
  return false;
};

export const isProfileComplete = (account) => {
  if (!account) return false;
  const { firstName, lastName, phone, email } = account;

  if (!(firstName && lastName && (phone || email))) return false;
  return true;
};

export const isProfileConfirmedByAdmin = (account) => {
  if (!account) return false;
  const { isConfirmedByAdmin } = account;
  if (isConfirmedByAdmin) return true;
  return false;
};
