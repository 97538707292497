import { iLoginForm, iRecoverForm, iResetForm } from "./types";

export const initialLoginForm: iLoginForm = {
  email: "",
  password: "",
  emailValidation: null,
  passwordValidation: null,
};
export const initialRecoverForm: iRecoverForm = {
  email: "",
  emailValidation: null,
};
export const initialResetForm: iResetForm = {
  password: "",
  passwordConfirm: "",
};

export const loginFormValidationRules = {
  email: { required: true, email: true },
  password: { required: true },
};
