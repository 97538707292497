/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, createContext, useReducer } from "react";
import {
  addToList,
  addToListById,
  removeFromList,
  removeFromListById,
} from "services/helperService";
import { Socket } from "socket.io-client";

type Action = {
  type:
    | "INITIAL_DATA_FETCHED"
    | "SET_LOADING_STATE"
    | "SET_SOCKET_HANDLER"
    | "SET_CURRENT_USER"
    | "SET_AUTH_TOKEN"
    | "SET_UNREAD_CHATS"
    | "INC_UNREAD_CHATS"
    | "DEC_UNREAD_CHATS"
    | "SET_NEW_ANNOUNCEMENTS"
    | "INC_NEW_ANNOUNCEMENTS"
    | "DEC_NEW_ANNOUNCEMENTS"
    | "UPDATE_ACTIVE_FEATURES"
    | "SET_ACTIVE_FEATURES"
    | "UPDATE_CONFIG"
    | "UPDATE_RELEASE_DATES"
    | "SET_SUBSCRIPTION_INFO"
    | "SET_PROFILE";
  payload: LooseObject;
};

export interface SubscriptionPeriodInfo {
  start_date?: number;
  current_period_start?: number;
  current_period_end?: number;
}
interface State {
  socketHandler: null | Socket;
  currentUser: LooseObject;
  authToken: string;
  unreadChats: number[];
  newAnnouncements: LooseObject[];
  activeFeatures: string[];
  config: JObj[];
  releaseDates: JObj[];
  profile: LooseObject;
  initialDataFetching: boolean;
  isLoading: boolean;
  subscriptionInfo?: SubscriptionPeriodInfo;
}

interface ContextType {
  state: State;
  dispatch: Dispatch<Action>;
}

const initialState: State = {
  socketHandler: null,
  currentUser: {},
  authToken: "",
  unreadChats: [],
  newAnnouncements: [],
  activeFeatures: [],
  config: [],
  releaseDates: [],
  profile: {},
  initialDataFetching: true,
  isLoading: false,
};

export const UserContext = createContext<ContextType | undefined>(undefined);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "INITIAL_DATA_FETCHED":
      return { ...state, initialDataFetching: false };
    case "SET_LOADING_STATE":
      return { ...state, isLoading: action.payload.isLoading };
    case "SET_SOCKET_HANDLER":
      return { ...state, socketHandler: action.payload.socketHandler };
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload.currentUser };
    case "SET_AUTH_TOKEN":
      return { ...state, authToken: action.payload.authToken };
    case "UPDATE_ACTIVE_FEATURES":
      return {
        ...state,
        activeFeatures: addToList(
          [...state.activeFeatures],
          action.payload.feature
        ),
      };
    case "SET_ACTIVE_FEATURES":
      return {
        ...state,
        activeFeatures: action.payload.features,
      };
    case "UPDATE_CONFIG":
      return {
        ...state,
        config: [...state.config, ...action.payload.config],
      };
    case "UPDATE_RELEASE_DATES":
      return {
        ...state,
        releaseDates: [...state.releaseDates, ...action.payload.releaseDates],
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.payload.profile,
      };
    case "SET_UNREAD_CHATS":
      return {
        ...state,
        unreadChats: action.payload.unreadChats,
      };
    case "INC_UNREAD_CHATS":
      return {
        ...state,
        unreadChats: addToList(
          [...state.unreadChats],
          action.payload.unreadChatId
        ),
      };
    case "DEC_UNREAD_CHATS":
      return {
        ...state,
        unreadChats: removeFromList(
          [...state.unreadChats],
          action.payload.unreadChatId
        ),
      };
    case "SET_NEW_ANNOUNCEMENTS":
      return {
        ...state,
        newAnnouncements: action.payload.newAnnouncements,
      };
    case "INC_NEW_ANNOUNCEMENTS":
      return {
        ...state,
        newAnnouncements: addToListById(
          [...state.newAnnouncements],
          action.payload.newAnnouncement
        ),
      };
    case "DEC_NEW_ANNOUNCEMENTS":
      return {
        ...state,
        newAnnouncements: removeFromListById(
          [...state.newAnnouncements],
          action.payload.announcement
        ),
      };
    case "SET_SUBSCRIPTION_INFO":
      return {
        ...state,
        subscriptionInfo: action.payload.subscriptionInfo,
      };

    default:
      return state;
  }
};

export const UserContextProvider: FC<{ children: any }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
