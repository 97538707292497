import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../../pricing.css";
import * as userServices from "../../services/userServices.js";
import swal from "sweetalert";
import "./css/stripe.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logOut } from "../../services/userServices";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { subscriptionFailSwalArgs } from "./UpdateCheckout";
import { makeToast } from "helpers/common";

let { REACT_APP_ANNUAL_STRIPE_ID, REACT_APP_MONTHLY_STRIPE_ID } = process.env;

export default function Checkout(props) {
  console.log(props);

  const stripe = useStripe();
  const elements = useElements();

  // This isn't getting set??
  // This represent a product price in the Stripe universe
  const [priceId, setPriceId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("yearly")) {
      setPriceId("yearly")
    }
    setPriceId("monthly")
  }, [location])


  let btnRef = useRef();

  const btnPay = {
    margin: "0 auto",
    display: "block",
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontSize: "15px",

        "::placeholder": {
          color: "#CFD7E0",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleDisabled = (e) => {
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "disabled");
      handlePayment(e);
    }
  };

  const handlePayment = async (event) => {
    toast.info("Processing", {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
    // Block native form submission.
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      btnRef.current.removeAttribute("disabled");
      makeToast("Payment Failed, Try Again!",'error');
    } else {
      let priceIdToSubmit = priceId || "monthly";

      let data = {
        customerId: props.customerId,
        paymentMethodId: paymentMethod.id,
        priceId: priceIdToSubmit === "yearly" ? REACT_APP_ANNUAL_STRIPE_ID : REACT_APP_MONTHLY_STRIPE_ID,
      };

      userServices
        .createSubscription(data)
        .then((response) => {
          console.log("stripe response: " + JSON.stringify(response));
          if (response.isSuccessful) {
            onSuccessResponse();
          } else {
            // handle an unsuccessful subscription response
            swal(subscriptionFailSwalArgs);
          }
        })
        .catch((err) => {
          console.log("error with server: " + JSON.stringify(err));
          btnRef.current.removeAttribute("disabled");
        });
    }
  };

  const onErrorSubscription = () => {
    makeToast("Please Verify Payment",'error');
    btnRef.current.removeAttribute("disabled");
  };

  const onSuccessResponse = (response) => {
    swal({
      title: "Your MyRep account has been created. Thank you for your payment!",
      text: "Please confirm your email before logging in.",
    });
    logOut();
    setTimeout(() => {
      swal.close();
      window.location.replace("/check/email");
    }, 3000);
  };

  const radio = {
    display: "block",
  };

  return (
    <React.Fragment>
      <div className="card bg-light">
        <div className="inline-form">
          <article class="card-body mx-auto">
            {/* <div class="containur">
      
          <form class="form">
            <section class="plan cf">
            
              <div class="text-center">
                <input
                  onChange={() => handleType("1")}
                  type="radio"
                  name="radio1"
                  id="free"
                />
                <label class="free-label four col" for="free">
                  Monthly <small> - $99.99</small>
                </label>

                <input
                  type="radio"
                  name="radio1"
                  id="premium"
                  onChange={() => handleType("2")}
                />
                <label class="premium-label four col" for="premium">
                  Annual <small> - $999.99</small>
                </label>
              </div>
            </section>
          </form>
        </div> */}
            {/* <div class="background"> */}
            {/* <div class="containerr"> */}

            {/* <div className="stripe_form"> */}
            {/* <label>Card details</label> */}

            <form>
              <p class="text-center">2. Select your account type</p>
              {/* <div class="form-group row">
              <div class="col-sm-8">
                <input
                  class="radio-inline"
                  id="one"
                  type="radio"
                  onChage={() => handleType(1)}
                />
                <label class="radio-inline" for="one">
                  
                </label>

                <input
                  class="radio-inline"
                  id="two"
                  type="radio"
                  onChage={() => handleType(2)}
                />
                <label class="radio-inline" for="two">
                  Annual <strong> $999.99 / month</strong>
                </label>
              </div>
            </div> */}
              <div className="text-center">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    checked={priceId !== "yearly"}
                    onChange={() => setPriceId("monthly")}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Monthly <strong>$99.99/month</strong>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    checked={priceId === "yearly"}
                    onChange={() => setPriceId("yearly")}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Annual <strong> $999/year</strong>
                  </label>
                </div>
              </div>
              <input type="hidden" name="token" />
              <p class="text-center">3. Enter your payment information</p>
              <div class="group">
                <label>
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </label>
              </div>
              <div class="group">
                <label>
                  <span>First name</span>
                  <input id="first-name" name="first-name" class="field" />
                </label>
                <label>
                  <span>Last name</span>
                  <input id="last-name" name="last-name" class="field" />
                </label>
              </div>
              <div class="group">
                <label>
                  <span>Address</span>
                  <input id="address-line1" name="address_line1" class="field" />
                </label>
                <label>
                  <span>Address (cont.)</span>
                  <input id="address-line2" name="address_line2" class="field" />
                </label>
                <label>
                  <span>City</span>
                  <input id="address-city" name="address_city" class="field" />
                </label>
                <label>
                  <span>State</span>
                  <input id="address-state" name="address_state" class="field" />
                </label>
                <label>
                  <span>ZIP</span>
                  <input id="address-zip" name="address_zip" class="field" />
                </label>
                <label>
                  <span>Country</span>
                  <select name="address_country" id="address-country" class="field">
                    <option selected="selected" value="US">
                      United States
                    </option>
                  </select>
                </label>
              </div>
              <button
                className="btn btn--outline btn--white payBtn"
                type="button"
                style={btnPay}
                ref={btnRef}
                onClick={handleDisabled}
              // onClick={handlePayment}
              // disabled={!stripe}
              >
                Pay
              </button>
              <div className="row justify-content-center">
                <span className="press_once">
                  {" "}
                  * Please press once and wait for confirmation box. Do not refresh.
                </span>
              </div>
              <div class="outcome">
                <div class="error"></div>
                <div class="success">
                  Success! Your Stripe token is <span class="token"></span>
                </div>
              </div>
            </form>
            {/* </div> */}
            {/* </div> */}
            {/* </div>{" "} */}
          </article>
        </div>
      </div>
    </React.Fragment>
  );
}

// export default Checkout;
