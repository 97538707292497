import React from "react";

const LoadingSpinner = ({ variant = "default" }) => {
  return (
    <div className={`lds-roller ${variant === "alt" ? "alt" : ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
