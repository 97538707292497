import React, { useEffect, useReducer, useState } from "react";
import "./auth.css";
import LoginInput from "./common/LoginInput";
import { iRecoverForm } from "./common/types";
import { initialLoginForm } from "./common/initialValues";
import { isValidEmail } from "components/common/utilities";
import * as passwordService from "services/emailService";
import swal from "sweetalert";

interface RecoverPasswordProps {}

interface PasswordState {
  email: string;
}

type Action = { type: "SET_EMAIL"; payload: string } | { type: "RESET_FORM" };

const passwordReducer = (
  state: PasswordState,
  action: Action
): PasswordState => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "RESET_FORM":
      return { email: "" };
    default:
      return state;
  }
};
const RecoverPassword: React.FC<RecoverPasswordProps> = () => {
  const [formData, setFormData] = useState<iRecoverForm>(initialLoginForm);
  const [state, dispatch] = useReducer(passwordReducer, { email: "" });

  const handleChange = (prop: string) => (value: string) => {
    let validation: string | null = "";
    switch (prop) {
      case "email":
        if (!isValidEmail(value)) {
          validation = "Please enter a valid email address.";
        } else validation = null;

      default:
        break;
    }
    setFormData((prev: iRecoverForm) => ({
      ...prev,
      [prop]: value,
      [`${prop}Validation`]: validation,
    }));
  };

  const onSuccess = () => {
    swal({
      title: "Email Sent",
      text: "Please follow the link in the email to reset your password.",
      icon: "success",
    });
  };

  const onFailure = (error: Error) => {
    swal({
      icon: "error",
      text: error.message,
    });
  };
  const handleSubmit = () => {
    passwordService
      .forgotPass(formData.email)
      .then((res) => {
        if (res.success) return onSuccess();

        const error = res.error || "Error";
        throw new Error(error);
      })
      .catch(onFailure);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch({ type: "RESET_FORM" });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="login-container">
      <div className="login-content-container">
        <p className="login-form-title">Recover your password</p>
        <div className="login-form-container">
          <LoginInput
            label="Please verify your registered email"
            value={formData?.email}
            name={"email"}
            onChange={handleChange}
            type="email"
            helperText={formData.emailValidation}
            placeholder="Enter registered email"
          />

          <button className="login-form-btn" onClick={handleSubmit}>
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
