import React from "react";
import TextInput from "../common/TextInput.js";
import PasswordInput from "../common/PasswordInput.js";
import { Link } from "react-router-dom";
import "../../sass/zeplin/BasicForm.scss";
import "./wizard.css";


export default function Step1(props) {

  return (
    <React.Fragment>
      <div className="BasicForm">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <TextInput
            type="email"
            label="Email"
            name="email"
            value={props?.formData?.email?.value}
            onChange={props?.handleChange}
            style={{ marginBottom: 32 }}
          />
          <PasswordInput
            type="password"
            name="password"
            label="Password"
            value={props?.formData?.password?.value}
            onChange={props?.handleChange}
            containerStyles={{ width: "100%", marginBottom: 32 }}
          />
          <PasswordInput
            containerStyles={{ width: "100%", marginBottom: 32 }}
            label="Confirm password"
            type="password"
            value={props?.formData?.confirmPassword?.value}
            onChange={props?.handleChange}
            name="confirmPassword"
          />
          {props?.errors?.confirmPassword && <p style={{ marginTop: -20, fontSize: 16, color: 'firebrick' }}> {props?.errors.confirmPassword} </p>}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <input
              type="checkbox"
              onClick={props?.agreeState}
              style={{ marginRight: 8, cursor: "pointer" }}
            />
            <span
              onClick={props?.handleTermsModal}
              style={{ marginRight: 24, color: "#224894", cursor: "pointer" }}
            >
              Agree to Terms
            </span>

            <input
              type="checkbox"
              onClick={props?.agreePrivacy}
              style={{ marginRight: 8, cursor: "pointer" }}
            />
            <span
              onClick={props?.handlePrivacyModal}
              style={{ color: "#224894", cursor: "pointer" }}
            >
              Agree to Privacy
            </span>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <button
                  type="button"
                  className="Button"
                  disabled={props?.isLoading}
                  style={{ width: 265 }}
                  onClick={props?.handleSubmit}
                >
                  {!props?.isLoading ? " Email verification" : <i className="fa fa-spinner fa-spin"></i>}
                </button>
              </div>
            </div>
          </div>
          <p className="text-center">
            <Link to="/login">Have an account? Log In</Link>
          </p>
        </form>
      </div>
    </React.Fragment>
  );
}
