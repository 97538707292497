import React, { useMemo, useState } from "react";
import "./common.css";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
interface LoginInputProps {
  label: string;
  placeholder?: string;
  value?: string;
  name: string;
  onChange: (prop: string) => (value: string) => void;
  type: string;
  helperText?: string | null;
  onEnter?: () => Promise<void>;
}
const LoginInput: React.FC<LoginInputProps> = ({
  label,
  placeholder,
  value,
  name,
  onChange,
  type,
  helperText,
  onEnter,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => setShowPassword((prev) => !prev);
  const inputType = useMemo(() => {
    if (type !== "password") return type;
    if (showPassword) return "text";
    return type;
  }, [type, showPassword]);
  const passwordIcon = useMemo(() => {
    if (type !== "password") return null;
    if (showPassword)
      return (
        <IoEyeOutline className="show-password-icon" onClick={togglePassword} />
      );
    return (
      <IoEyeOffOutline
        className="show-password-icon"
        onClick={togglePassword}
      />
    );
  }, [type, showPassword]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      e.preventDefault();
      await onEnter();
    }
  };
  return (
    <div className="login-input-container">
      <p className="login-input-label">{label}</p>
      <div className="input-items-container">
        <input
          type={inputType}
          className="login-input"
          placeholder={placeholder || "Enter"}
          value={value || ""}
          name={name || "input"}
          onChange={(e) => onChange(name)(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {passwordIcon}
      </div>
      {helperText && <p className="login-from-helper-text">{helperText}</p>}
    </div>
  );
};

export default LoginInput;
