import { useContext } from "react";
import { UserContext } from "components/context/UserContext";
import { logOut } from "services/userServices";
import sweetalert from "sweetalert";
import { setAuthToken } from "./authTokenManager";

function useLogout(redirect = "/login") {
  const { dispatch } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: true } });
      await logOut();
      setAuthToken(null);
      sweetalert({
        title: "Goodbye",
        text: "See you next time!",
        icon: "success",
        buttons: false,
        closeOnEsc: false,
        closeOnClickOutside: false,
      });

      // ADDED TO LOG USER OUT
      // dispatch({
      //   type: "SET_CURRENT_USER",
      //   payload: { currentUser: {} },
      // });
      setTimeout(() => {
        window.location.replace(redirect);
      }, 1000);
    } catch (e) {
      sweetalert({
        timer: 2000,
        title: "Oops!",
        text: "Some error occured",
        icon: "error",
      });
      dispatch({ type: "SET_LOADING_STATE", payload: { isLoading: false } });
    }
  };

  return handleLogout;
}

export default useLogout;
