import NotFound from "components/NotFound";
import ClientDashboard from "components/client/dashboard/ClientDashboard";
import { UserContext } from "components/context/UserContext";
import AdminLayout from "components/layout/AdminLayout";
import RepDashboard from "components/rep/dashboard/RepDashboard";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const shouldHide = (path: string) => {
  return ["/rep/onboarding"].some((p: string) => path.includes(p));
};

export const RenderDashboard = (props: any) => {
  const { state: appState } = useContext(UserContext)!;
  const { currentUser } = appState;

  const location = useLocation();

  if ([2, 6, 7, 8].includes(currentUser?.roleId)) {
    return <ClientDashboard currentUser={currentUser}>{props.children}</ClientDashboard>;
  }

  if ([3, 4, 5].includes(currentUser?.roleId)) {
    return (
      <RepDashboard currentUser={currentUser} hide={shouldHide(location.pathname)}>
        {props.children}
      </RepDashboard>
    );
  }

  if (currentUser?.roleId === 1) {
    return <AdminLayout currentUser={currentUser}> {props.children} </AdminLayout>;
  }

  return props.children;
};

const RouteGuard = (props: any) => {
  const { state } = useContext(UserContext)!;
  const { currentUser } = state;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!currentUser.id) {
      history.replace("/login", { redirectTo: location.pathname });
    }
  }, [currentUser]);

  if (!currentUser.id) {
    return null;
  }

  const renderRoute = () => {
    if (props.roles.includes(currentUser.roleName) && currentUser.roleId !== 8) {
      return props.children;
    }

    if (currentUser.roleId === 8 && props.roles.includes("Delegated User")) {
      return props.children;
    }

    return <NotFound />;
  };

  return <RenderDashboard>{renderRoute()}</RenderDashboard>;
};

export default RouteGuard;
