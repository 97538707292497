import React from "react";
import "../../sass/zeplin/Card.scss";

const Card = ({ shadow = false, isSelected, isSelectable, children, style, isClickable = false, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        backgroundColor: "white",
        borderRadius: "10px",
        boxSizing: "border-box",
        ...style,
      }}
      {...rest}
      className={
        "Card" +
        (isSelectable ? " --selectable" : "") +
        (isSelected ? " --selected" : "") +
        (isClickable ? " --hoverable" : "") +
        (shadow ? " Shadow" : "") +
        (rest.className ? ` ${rest.className}` : "")
      }
    >
      {children}
    </div>
  );
};

export default Card;
