import { getAuthToken } from "components/common/authTokenManager";
import { onGlobalError } from "../services/serviceHelper";
const { REACT_APP_V2_API_ENDPOINT } = process.env;

export async function v2Request(route, fetchConfig) {
  const token = getAuthToken();

  const config = {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers: { "Content-Type": "application/json", "X-Token": token || "" },
    ...fetchConfig,
  };

  try {
    const res = await fetch(REACT_APP_V2_API_ENDPOINT + route, config);
    return await res.json();
  } catch (err) {
    return onGlobalError(err);
  }
}
