import React from "react";
import "./css/redesign-components.css";
import "./css/benefits-section.css";
import rightSection from "assets/svg/home/benefits/rightSection.svg";
import { IconType } from "react-icons";
import { BsClockHistory } from "react-icons/bs";
import { FaUnlock } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { HiCubeTransparent } from "react-icons/hi";
interface BenefitsSectionProps {}

interface BannerItem {
  title: string;
  description: string;
  icon: IconType;
}
const BANNER_ITEM_DATA = [
  {
    icon: BsClockHistory,
    title: "Time-saving Automations",
    description:
      "It’s time to leave clunky, non-native calendars behind. MyRep’s automated appointment scheduling removes the need for offices to carry out confirmations, follow-up calls, and manual calendar entries.",
  },
  {
    icon: FaUnlock,
    title: "Intuitive Access",
    description:
      "Through MyRep’s intuitive user interface, medical practices can get started immediately without the hassle of learning yet another platform. For a firsthand look, be sure to request a free demo.",
  },
  {
    icon: IoCalendarOutline,
    title: "Custom Schedules",
    description:
      "MyRep enables medical practices to set boundaries around their schedules, aligning with office policies, and tailored to suit office needs.",
  },
  {
    icon: HiCubeTransparent,
    title: "Transparent Scheduling",
    description:
      "With easy-to-add meeting availability, MyRep keeps both parties informed and minimizes unbooked appointments by alerting available representatives. In fact, over 99% of the appointments made available are successfully booked. Rest assured, representatives do not see your calendar or its details.",
  },
];

const BenefitsSection: React.FC<BenefitsSectionProps> = () => {
  const getBannerItem = (itemData: BannerItem, index: number) => {
    const IconComponent = itemData.icon;
    return (
      <div className="benefits-item-container" key={index}>
        <div className="benefits-icon-container">
          <IconComponent fontWeight={"bold"} />
        </div>

        <span className="benefits-item-title">{itemData.title}</span>
        <span className="benefits-item-description">{itemData.description}</span>
      </div>
    );
  };

  return (
    <div className="section-container">
      <div className="benefits-layout">
        <div className="left-section">
          <div className="benefits-top-container">
            <div
              className="description-pill-btn"
              style={{ alignSelf: "flex-start" }}
            >{`Benefits for Medical Practices`}</div>
            <p className="section-heading">Simplify Your Scheduling Process</p>
            <p className="section-sub-heading">
              Through MyRep’s intuitive user interface, medical practices can get started immediately
            </p>
          </div>

          <div className="items-container">{BANNER_ITEM_DATA.map((item, index) => getBannerItem(item, index))}</div>
        </div>
        <img src={rightSection} alt={``} className="benefits-image" />
      </div>
    </div>
  );
};

export default BenefitsSection;
