import { io } from "socket.io-client";


export const getIoHost = () => {
  let ioHost;
  switch (process.env.REACT_APP_ENV) {
    case "production": {
      ioHost = "https://services.myrepapp.com";
      break;
    }
    case "staging": {
      ioHost = "https://stage-v2.myrepapp.com";
      break;
    }
    default: {
      ioHost = "http://localhost:8888";
    }
  }
  return ioHost;
};


const startWebsocketConnection = async (connection) => {
  try {
    await connection.start();
    console.log("Websockets connection established");
  } catch (err) {
    // console.assert(connection.state === HubConnectionState.Disconnected);
    console.error("Websockets Connection Error: ", err);
    setTimeout(() => startWebsocketConnection(connection), 5000);
  }
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupWebsocketConnection = (connectionHub) => {
  // const options = {
  //   logMessageContent: isDev,
  //   logger: isDev ? LogLevel.Warning : LogLevel.Error,
  // };

  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
 
  const socketHandler = io(getIoHost());

  socketHandler.on("connect", () => {
    console.log("CONNECTED");
  })

  socketHandler.on("disconnect", () => {
    console.log("DISCONNECTED");
  })

  // const connection = new HubConnectionBuilder()
  //   .withUrl(connectionHub, options)
  //   .withAutomaticReconnect()
  //   // .configureLogging(LogLevel.Information)
  //   .configureLogging(LogLevel.Trace)
  //   .build();

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  // connection.serverTimeoutInMilliseconds = 60000;

  // re-establish the connection if connection dropped
  // connection.onclose((error) => {
  //   console.assert(connection.state === HubConnectionState.Disconnected);
  //   console.log(
  //     "Connection closed due to error. Try refreshing this page to restart the connection",
  //     error
  //   );
  // });

  // connection.onreconnecting((error) => {
  //   console.assert(connection.state === HubConnectionState.Reconnecting);
  //   console.log("Connection reconnecting.", error, connection);
  // });

  // connection.onreconnected((connectionId) => {
  //   console.assert(connection.state === HubConnectionState.Connected);
  //   console.log("Connection reestablished. Connected with connectionId", connectionId);
  // });

  // startSignalRConnection(connection);

  // return connection;
  return socketHandler;
};

// Determine if the given connection is in a valid state
export const connectionIsValid = (connection) => {
  if (connection === null) {
    return false;
  }
  // if (
  //   connection.state === HubConnectionState.Connected ||
  //   connection.state === HubConnectionState.Reconnecting
  // ) {
  //   return true;
  // }
  return false;
};