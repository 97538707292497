import React from "react";
import * as filesService from "../../services/filesService";
import "../../sass/zeplin/FileUpload.scss";
import { makeToast } from "helpers/common";

const FileUpload = (props) => {
  const { onSuccess = null, onError = () => {}, onStartUpload = null } = props;
  const handleChange = async (e) => {
    const fileList = e.target.files;
    if (fileList[0].size > 2000000) {
      alert("Sorry, this file's size exceeds the 2MB limit");
    } else {
      const formData = new FormData();
      if (fileList) {
        try {
          for (let file of fileList) {
            formData.append("files", file);
          }
          if (onStartUpload && typeof onStartUpload === "function") onStartUpload();
          else makeToast("Uploading", "info", 1000);

          const res = await filesService.uploadFile(formData);
          if (onSuccess && typeof onSuccess === "function") onSuccess(res);
          else makeToast("Upload Success", "success", 1000);
        } catch (e) {
          onError(e);
        }
      }
    }
  };

  return (
    <label className={`FileUpload ${props.labelClassName}`} style={props.style}>
      {props.children}
      <input type="file" name="file" className={`FileUpload ${props.className}`} onChange={handleChange} />
    </label>
  );
};

export default FileUpload;
