import React, { useState } from 'react';
import TextInput from './TextInput';

const PasswordInput = ({ containerStyles = {}, ...rest }) => {
    const [ isPasswordVisible, setPasswordVisibility ] = useState(false)
    return (
        <div style={{position: 'relative', maxWidth: '100%', ...containerStyles }}>
            <TextInput
                { ...rest }
                type={ isPasswordVisible ? "text" : "password" }
            />
            <img src="/eye-icon.svg" onClick={() => { setPasswordVisibility(!isPasswordVisible)}} style={{ position: 'absolute', top: 0, right: 10, width: 26, height: '100%', zIndex: 1 }} />
        </div>
    )
};

export default PasswordInput;
