import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./../css/header.css";
import { HOME_SECTIONS } from "helpers/common";
import { Navbar } from "react-bootstrap";

interface HeaderProps {
  scrollToSection: (sectionName: string) => void;
}
const Header: React.FC<HeaderProps> = ({ scrollToSection }) => {
  const history = useHistory();
  return (
    <Navbar collapseOnSelect expand="md" style={{ padding: "0em 1em" }}>
      <Navbar.Brand href="/">
        <img
          alt="MyRep"
          className="myreplogo"
          src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <div className="h-page-links">
          <p className="h-link" onClick={() => scrollToSection(HOME_SECTIONS.benefits)}>
            Benefits
          </p>
          <p className="h-link" onClick={() => scrollToSection(HOME_SECTIONS.howItWorks)}>
            How it works
          </p>
          <p className="h-link" onClick={() => scrollToSection(HOME_SECTIONS.testimonial)}>
            Testimonials
          </p>
          <p className="h-link" onClick={() => scrollToSection(HOME_SECTIONS.faq)}>
            FAQ
          </p>
        </div>
        <div className="nav-btn-container">
          <Link to="/register" className="h-ca-link">
            Create Account
          </Link>
          <button className="h-login-btn" onClick={() => history.push("/login")}>
            Login
            {/* <Link data-cy="login" to="/login" style={{ color: "#fff", fontSize: 16, fontWeight: 700 }}>
            </Link> */}
          </button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
