import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../assets/images/logo/logo.png";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NavbarHeader = () => {
  const router = useHistory();

  const handleLogin = () => {
    router.push("/login");
  };

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <img style={{ width: 150 }} src={Logo} alt="My Rep" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse className="justify-content-end">
          <Button onClick={handleLogin} variant="outline-secondary" size="sm">
            Login
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarHeader;
