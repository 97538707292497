import React from "react";
import useLogout from "./useLogout";

const TokenErrorMessage = () => {
  const logout = useLogout();

  return (
    <div style={{ padding: "2rem" }}>
      <img
        className="Logo"
        alt="MyRep"
        src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
      />
      <div style={{ maxWidth: "100%", width: 500 }} className="mx-auto">
        <h2 style={{ marginBottom: "1rem" }}>Invalid Token</h2>
        <p>
          You're not authorized to access this because the token you provided
          has expired. Please login again to make this request.
        </p>

        <button onClick={logout} className="btn">
          Logout
        </button>
      </div>
    </div>
  );
};

export default TokenErrorMessage;
