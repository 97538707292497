import { Appointment } from "components/rep/accounts/cardNewDesign/types";
import moment from "moment";
import toast from "react-hot-toast";

const DATE_FORMAT: string = "M/D/YY";
const APPOINTMENT_DAY_FORMAT: string = "MMM Do, YYYY";
const TIME_FORMAT: string = "h:mma";
const WEEK_DAY: string = "dddd";

const getDisplayDate = (date: string) => {
  return moment(date).format("M/D/YY");
};

const makeToast = (message: string, type: string, time: number = 3000) => {
  const t =
    type === "success" ? toast.success : type === "error" ? toast.error : toast;
  t(message, {
    duration: time,
    position: "top-center",
  });
};
const sortAppointments = (appointments: Appointment[]) => {
  return [...appointments].sort((a, b) => {
    const dateA = moment(a.date, "YYYY-MM-DD");
    const dateB = moment(b.date, "YYYY-MM-DD");

    return dateA.diff(dateB);
  });
};
const formatTime = (appointmentTimeInSeconds: number) => {
  const hours = Math.floor(appointmentTimeInSeconds / 3600) % 12;
  const minutes = Math.floor((appointmentTimeInSeconds % 3600) / 60);
  const isAM = Math.floor(appointmentTimeInSeconds / 3600) < 12;

  const formattedHours = hours === 0 ? 12 : hours;

  const formattedMinutes = String(minutes).padStart(2, "0");

  const ampm = isAM ? "AM" : "PM";

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
const HOME_SECTIONS = {
  benefits: "benefitsRef",
  howItWorks: "howItWorksRef",
  testimonial: "testimonialsRef",
  faq: "faqRef",
};
export {
  DATE_FORMAT,
  WEEK_DAY,
  getDisplayDate,
  TIME_FORMAT,
  makeToast,
  APPOINTMENT_DAY_FORMAT,
  HOME_SECTIONS,
  sortAppointments,
  formatTime,
};
