import React from "react";

const MainFooter = () => {
  return (
    <React.Fragment>
      <div className="footer home_footer home_footer_font" style={{ padding: "5rem 0"}}>
        <div className="iLfooter">
          <footer className="font-small blue-grey footerspace row">
            <div className="container text-center">
              <div className="dark-grey-text row">
                <div className="col-sm-12 col-md-4" style={{ boxSizing: "border-box", marginBottom: "1rem"}}>
                  © { new Date().getFullYear() } MyRep. All rights reserved.
                </div>
                <div className="col-sm-12 col-md-4" style={{ boxSizing: "border-box", marginBottom: "1rem"}}>
                  <div className="footer-info">
                    <a className="footer_email">support@myrepapp.com</a>
                    <span className="pipe"> | </span>
                    <a className="footer_number">
                      833-MYREP37 <br/> (833-697-3737)
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4" style={{ boxSizing: "border-box"}}>
                  <div className="footer-info">
                    <a target="_blank" href="https://myrepapp-assets.s3-us-west-1.amazonaws.com/privacy.pdf">
                      Privacy Policy
                    </a>
                    <span className="pipe"> | </span>
                    <a target="_blank" href="https://myrepapp-assets.s3-us-west-1.amazonaws.com/terms.pdf">
                      Terms and Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainFooter;
