import axios from "axios";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL } = process.env;
let endpoint = REACT_APP_API_URL + "/admin"; //dev
//const endpoint = "http://52.53.243.189/api/user"; //production

let search = (query) => {
  const config = {
    method: "GET",
    url: endpoint + `/search?offset=0&row_count=10&query=${query}`,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let edit = (payload, id) => {
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/edit/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let editCompany = (payload, id) => {
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let editUser = (payload, id) => {
  console.log(payload, id);
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/user/" + id,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getAllProfiles = (offset, row_count) => {
  console.log(offset, row_count, "axios call");
  const config = {
    method: "GET",
    url: endpoint + "?offset=" + offset + "&rowcount=" + row_count,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let deleteUserById = (id) => {
  const config = {
    method: "DELETE",
    url: `${endpoint}/delete/${id}`,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getCount = () => {
  const config = {
    method: "GET",
    url: endpoint + "/count",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

// let getMessage = () => {
//   const config = {
//     method: "GET",
//     url: endpoint + "/announcement",
//     withCredentials: true,
//     crossdomain: true,
//     headers: { "Content-Type": "application/json" },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

let getAllReferrals = (offset, row_count) => {
  console.log(offset, row_count, "axios call");
  const config = {
    method: "GET",
    url: endpoint + "/referal?offset=" + offset + "&rowcount=" + row_count,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getAllAnnouncements = (offset, row_count, is_admin, is_allAnnouncements = false) => {
  console.log(offset, row_count, "axios call");
  const config = {
    method: "GET",
    url:
      endpoint +
      "/announcements?offset=" +
      offset +
      "&rowcount=" +
      row_count +
      "&is_admin=" +
      is_admin +
      "&is_allAnnouncements=" +
      is_allAnnouncements,
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let createAnnouncement = (payload, id) => {
  console.log(payload, id);
  const config = {
    data: payload,
    method: "POST",
    url: endpoint + "/createannouncement",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let editAnnouncement = (payload, id) => {
  console.log(payload, id);
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/editannouncement",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let publishAnnouncement = (payload, id) => {
  console.log(payload, id);
  const config = {
    data: payload,
    method: "PUT",
    url: endpoint + "/publishannouncement",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let deleteAnnouncement = (payload, id) => {
  console.log(payload, id);
  const config = {
    data: payload,
    method: "DELETE",
    url: endpoint + "/deleteannouncement",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let setReadAnnouncement = (payload, id) => {
  const config = {
    data: payload,
    method: "POST",
    url: endpoint + "/setreadannouncement",
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export {
  search, // search for reps as an admin user
  edit, // OUTDATED
  editAnnouncement,
  getAllReferrals, // OUTDATED
  getCount, // OUTDATED
  editCompany, // OUTDATED
  editUser, // OUTDATED
  deleteUserById, // OUTDATED
  getAllProfiles, // OUTDATED
  getAllAnnouncements,
  createAnnouncement,
  publishAnnouncement,
  deleteAnnouncement,
  setReadAnnouncement,
};
