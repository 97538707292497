/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import "./css/redesign-components.css";
import "./css/faq-section.css";
import Accordion from "react-bootstrap/Accordion";

import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
interface FaqSectionProps {}
interface faq {
  question: string;
  answer: string;
}
const FAQ = [
  {
    question: "What is MyRep?",
    answer:
      "MyRep is a secure cloud-based platform designed to connect medical practices with sales representatives in the pharmaceutical industry, enabling reps to share valuable information about medical services, medicinal products, devices, and scientific insights that enhance patient care and support informed decision-making.",
  },
  {
    question: "How do I find a representative on MyRep?",
    answer:
      "Reps who are following your account can be searched by name, product, or company using our user-friendly search tool.",
  },
  {
    question: "Is there a cost to use MyRep?",
    answer:
      "Access to the MyRep platform is free for users. Some rep premium features may require a subscription, which will be clearly indicated.",
  },
  {
    question: "How do I switch away from the scheduling platform I am currently using?",
    answer:
      "The support team will be happy to guide you and will make the transition as easy as possible.  Our migration process is flexible.",
  },
  {
    question: "What types of appointments can I schedule through MyRep?",
    answer:
      "MyRep allows you to schedule a variety of appointments, including non-food related appointments, breakfasts, lunches, virtual consultations and many more.",
  },
  {
    question: "I'm used to my current way of doing things and I'm not comfortable with technology. How can MyRep help?",
    answer:
      " We understand that transitioning to new technology can be challenging. MyRep is designed with user-friendliness in mind, making it easy to navigate and integrate into your existing workflow. Our support team is here to guide you through the process, providing personalized assistance and resources to ensure you feel comfortable and confident using the platform. Plus, you can request a free demo to see how MyRep can streamline your processes without overwhelming you with complex technology.",
  },
];
const FaqSection: React.FC<FaqSectionProps> = () => {
  const [currentlyOpen, setCurrentlyOpen] = useState<string>("");

  const handleSelect = (currentSelection: any) => {
    setCurrentlyOpen(currentSelection);
  };
  return (
    <div className="section-container">
      <div className="benefits-layout">
        <div className="left-section">
          <div className="benefits-top-container">
            <div className="description-pill-btn" style={{ alignSelf: "flex-start" }}>{`FAQ`}</div>
            <p className="section-heading">
              Frequently asked
              <br /> questions
            </p>
          </div>
        </div>
        <div className="faq-right-section">
          <Accordion onSelect={handleSelect}>
            {FAQ.map((elem: faq, index: number) => (
              <React.Fragment key={index}>
                <Accordion.Toggle eventKey={`${index}`} className="accordion-toggle-btn">
                  <p className="accordion-question">{elem?.question}</p>
                  {currentlyOpen === `${index}` ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`} className="accordion-collapse-container">
                  <p className="accordion-answer">{elem?.answer}</p>
                </Accordion.Collapse>
              </React.Fragment>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
