import LoadingSpinner from "./LoadingSpinner";

const LoadingOverlay = () => (
    <div
      className="d-flex justify-content-center align-items-center position-fixed"
      style={{
        height: "100%",
        width: "100%",
        zIndex: 100,
        top: 0,
        left: 0,
        background: 'rgba(10,10,10,.3)'
      }}
    >
      <LoadingSpinner variant="alt" />
    </div>
  );

  export default LoadingOverlay;