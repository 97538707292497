import React, { useState } from "react";
import AdminNavbar from "../admin/dashboard/AdminNavbar";
import { Container } from "react-bootstrap";

const AdminLayout = (props) => {
  return (
    <div>
      <AdminNavbar user={props.user} />
      <Container style={{ marginTop: 20 }}>{props.children}</Container>
    </div>
  );
};

export default AdminLayout;
