import React from "react";

// An input with an inline label
const Select = ({ label = "", name = "", defaultValue = "", options, ...rest }) => {
  const select = (
    <select name={name} {...rest} className="Input" defaultValue={defaultValue}>
      {options.map((o, i) => (
        <option key={i} value={o.value || o.value === "" ? o.value : o}>
          {o.display || o.display === "" ? o.display : o}
        </option>
      ))}
    </select>
  );

  if (!label) return select;

  return (
    <div className="TextInputContainer" style={{ position: "relative" }}>
      <label style={{ color: "#1f4784", position: "absolute", left: 16, top: 0, fontSize: 12 }} htmlFor={name}>
        {label}
      </label>
      {select}
    </div>
  );
};

export default Select;
