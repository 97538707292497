import React from "react";

const Logo = ({ ...rest }) => {
  return (
    <img
      alt="MyRep"
      src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
      {...rest}
    />
  );
};

export default Logo;
