import axios from "axios";
import { v2Request } from "../helpers/request";
import * as helper from "./serviceHelper";

let { REACT_APP_API_URL, REACT_APP_V2_API_ENDPOINT } = process.env;
let endpoints = {};
endpoints.email = REACT_APP_API_URL + "/user/";
endpoints.appt = REACT_APP_API_URL + "/rep/calendar";
endpoints.referral = REACT_APP_API_URL + "/";

let confirmAcct = (guid, tokenType) => {
  let url = `/users/confirm/${guid}`;

  if (tokenType) {
    url += `?tokenType=${tokenType}`
  }

  return v2Request(url, { method: "POST" });
};

let userProfileConfirm = (guid) => {
  const config = {
    method: "GET",
    url: endpoints.email + "confirmProfile/" + guid,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let referallAcct = (guid) => {
  console.log(guid, "AXIOS");
  const config = {
    method: "GET",
    url: endpoints.email + "premier/" + guid,
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let forgotPass = (email) => {
  return v2Request("/users/password/forgot", {
    body: JSON.stringify({ email }),
    method: "POST",
  });
};

let resetPass = (payload) => {
  return v2Request("/users/password/reset", {
    body: JSON.stringify(payload),
    method: "POST"
  });
};

let emailRep = (formData) => {
  // Don't send a Content-Type with this (allow for the sending of attachments)
  return v2Request("/appointments/emailreps", { method: "POST", body: formData, headers: {} })
};

// let emailAllRep = (payload) => {
//   console.log(payload, "All");
//   const config = {
//     method: "POST",
//     url: endpoints.appt + "/emailreps",
//     data: payload,
//     withCredentials: true,
//     crossdomain: true,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// };

let emailAllRep = (formData) => {
  return emailRep(formData)
}

let chosenRestaurant = (conf) => {
  const config = {
    data: conf,
    method: "POST",
    url: endpoints.appt + "/final",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let apptEmail = (data) => {
  const config = {
    data: data,
    method: "POST",
    url: endpoints.appt + "/apptEmail",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let profileConfirmationEmail = () => {
  const config = {
    method: "POST",
    url: endpoints.email + "profileconfirmationemail",
    withCredentials: true,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export {
  referallAcct,
  emailAllRep,
  confirmAcct,
  userProfileConfirm,
  emailRep,
  forgotPass,
  chosenRestaurant,
  resetPass,
  apptEmail,
  profileConfirmationEmail
};
