import React from "react";
import { useHistory } from "react-router-dom";

const ServerIssueMessage = () => {
  const history = useHistory();

  const gotoHome = () => {
    history.push("/");
  };

  return (
    <div style={{ padding: "2rem" }}>
      <img
        className="Logo"
        alt="MyRep"
        src="https://myrepapp-assets.s3-us-west-1.amazonaws.com/MyRep-541f14d6-80fb-4888-97ea-a8484bdf9f47-resizedMyrep.png"
      />
      <div style={{ maxWidth: "100%", width: 500 }} className="mx-auto">
        <h2 style={{ marginBottom: "1rem" }}>Server Error</h2>
        <p>
          We're investigating an issue with our servers, and are trying to get
          things working again as soon as possible. Please revisit this page
          soon to return to what you were doing.
        </p>
        <button onClick={gotoHome} className="btn">
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default ServerIssueMessage;
